import React from 'react'
import Moment from 'moment'

import './profile.scss'
import SearchURLPreloader from 'components/shared/SearchURLPreloader'
import SEO from 'components/SEO/SEO'
import ItemSearchDisplay from 'components/shared/Item/ItemSearchDisplay'
import Review from 'components/shared/Reviews/Review'
import Layout from 'layouts/Page'
import ShareBtn from 'components/shared/ShareBtn'
import { getRoute } from 'utils'

class ProfilePage extends React.Component {
  state = {
    // TODO: Ideally these two values should be null and a loading screen should be used
    // but I don't want to mess with the jsx in this file right now
    profile_products: [],
    profile_reviews: [],
    profile: {},
    newuser_badge: false,
  }

  _qSnapToDocmap = qSnap => {
    return qSnap.docs.map(docSnap => {
      const doc = docSnap.data()
      doc.id = docSnap.id
      return doc
    })
  }

  loadReviews = async () => {
    try {
      const qSnap = await this.props.database.getReviewsByLoaner(
        this.props.data.id
      )
      // The following should be in the firebase service
      const profile_reviews = this._qSnapToDocmap(qSnap)
      this.setState({ profile_reviews })
    } catch (error) {
      this.setState({ profile_reviews: [] })
    }
  }

  loadProducts = async () => {
    try {
      const qSnap = await this.props.database.getFleetByOwner(
        this.props.data.id
      )
      // The following should be in the firebase service
      const profile_products = this._qSnapToDocmap(qSnap)
      this.setState({ profile_products })
    } catch (error) {
      this.setState({ profile_products: [] })
    }
  }

  componentDidMount() {
    this.loadReviews()
    this.loadProducts()
    this.checkUserAge()
  }

  checkUserAge() {
    // check if user is less than month old
    let user = this.props.data
    Moment.locale('en')
    let age_months = Moment(user.createdAt.toDate()).diff(Moment(), 'months')
    if (age_months > -2) {
      this.setState({ newuser_badge: true })
    }
  }

  render() {
    let user = this.props.data

    return (
      <div className="profile-container">
        <div className="profile-user-info">
          <div className="img-badge-container">
            {user.picture ? (
              <img src={user.picture} className="profile-img" />
            ) : (
              <svg
                className="profile-img"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="user-circle"
                role="img"
                viewBox="0 0 496 512"
                data-ember-extension="1"
              >
                <path
                  fill="currentColor"
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                />
              </svg>
            )}
            {this.state.newuser_badge ? (
              <div class="hover-tip">
                <a
                  class="profile-img_badge"
                  href="https://twitter.com/jorisderuiter"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="star"
                    class="svg-inline--fa fa-star fa-w-18"
                    role="img"
                    viewBox="0 0 576 512"
                    data-ember-extension="1"
                  >
                    <path
                      fill="currentColor"
                      d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
                    />
                  </svg>
                  <span class="hover-tiptext">New to Mellow</span>
                </a>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="profile-column">
            <div className="profile-header">
              <div>
                <h2>{user.firstName}</h2>
                <p>
                  <span>{user.city ? user.city + '·' : ''} Joined </span>{' '}
                  <span>
                    {Moment(user.createdAt.toDate()).format('MMM YYYY')}
                  </span>
                </p>
              </div>

              <ShareBtn
                name="profile"
                text={user.firstName + "'s profile on Mellow"}
                link={getRoute('PROFILE', { id: user.id })}
              />
            </div>
            <p className="italic bio">
              {user.description || `No bio for ${user.firstName} yet.`}
            </p>
            <div className="profile-column" />
          </div>
        </div>

        {this.state.profile_reviews.length !== 0 && (
          <div className="profile-column" style={{ paddingBottom: 30 }}>
            <p>{user.firstName}'s reviews</p>
            <div className="product-grid">
              {this.state.profile_reviews.map(item => (
                <Review
                  key={item.id}
                  className="profile-review-item"
                  name={item.renter_name}
                  rating={item.rating}
                  createdAt={item.createdAt.toDate()}
                  img={item.renter_img}
                  text={item.description}
                />
              ))}
            </div>
          </div>
        )}
        <div className="profile-column" style={{ paddingBottom: 100 }}>
          <p>{user.firstName}'s available items</p>
          <div className="product-grid">
            {this.state.profile_products.map(item => (
              <ItemSearchDisplay
                key={item.id}
                product={item}
                hideProfile={true}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <SearchURLPreloader load={{ u: false }} location={location}>
    {({ searchData: { u: data }, ...props }) => (
      <Layout location={location}>
        <SEO
          title={`${data.firstName}'s Profile · Borrow items from ${
            data.firstName
          }`}
          image={data.picture}
          pathname={location.pathname}
        />
        <ProfilePage {...props} data={data} />
      </Layout>
    )}
  </SearchURLPreloader>
)
