import React from 'react'
import Moment from 'moment'
import ReactStars from 'react-stars'

import './Review.scss'

export default class Review extends React.Component {
  render() {
    let { id, name, img, city, rating, text, createdAt, className } = this.props
    Moment.locale('en')

    return (
      <div className={`review-item ${className}`}>
        <div className="review-user">
          <div className="review-avatar-wrapper">
            <img className="review-user-photo" src={img} />
          </div>
          <div className="details-container">
            <div className="name">
              <div className="top-container">{name}</div>
            </div>
            <div className="review-city">
              {city || Moment(createdAt).format('MMM YYYY')}
            </div>
          </div>
          <div className="review-container">
            <ReactStars
              count={5}
              value={rating || 5}
              edit={false}
              size={16}
              color1={'#A9A9A9'}
              color2={'#444'}
            />
          </div>
        </div>
        <div className="review-quote">
          <p>{text}</p>
        </div>
      </div>
    )
  }
}
